<template>
  <div>
    <!-- - crud for vital types | medicines | payment methods | illnesses | history types | departments. -->

    <AdminSettings
      v-if="$root.admin.selectedSetting == 'system'"
      @updated="showmsg"
    />
    <VitalTypes v-if="$root.admin.selectedSetting == 'vital'" />
    <Departments v-if="$root.admin.selectedSetting == 'departments'" />
    <PaymentMethods v-if="$root.admin.selectedSetting == 'payment'" />
    <HistoryTypes v-if="$root.admin.selectedSetting == 'history'" />
    <v-snackbar v-model="snackbar" timeout="2000">
      {{ msg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// import ApiService from '@/core/services/api.service';
import VitalTypes from "./VitalTypes/VitalTypes";
import HistoryTypes from "./HistoryTypes/HistoryTypes";
import Departments from "./Departments/Departments";
import AdminSettings from "./AdminSettings/AdminSettings";
import PaymentMethods from "./PaymentMethods/PaymentMethods";

export default {
  components: {
    VitalTypes,
    HistoryTypes,
    Departments,
    AdminSettings,
    PaymentMethods,
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      msg: "",
      langs: {},
    };
  },
  methods: {
    showmsg(e) {
      if (e == "system_settings") {
        this.msg = "Updated System Settings Successfully";
        this.snackbar = true;
      }
    },
    setlanguages(e) {
      console.log("langs from gun", e);
      //for(let i =0; i< e.length; i++)
    },
    /*getLanguages() {
      this.$api
        .get("items/languages?fields=id,name,code,flag.private_hash")
        .then((data) => {
          //console.log("languages", data.data.data);
          let lang = {};
          for (let i = 0; i < data.data.data.length; i++) {
            lang[data.data.data[i].code] = {};
            //console.log("data",data.data.data[i])
            lang[data.data.data[i].code].id = data.data.data[i].id;
            lang[data.data.data[i].code].name = data.data.data[i].name;
            lang[data.data.data[i].code].code = data.data.data[i].code;
            lang[data.data.data[i].code].icon =
              data.data.data[i].flag.private_hash;
          }
          console.log("flat langs", lang);
          this.$gun
            .get("settings")
            .get("langs")
            .put(lang);
          this.$gun
            .get("settings")
            .get("langs")
            .on(this.setlanguages);
        });
    },*/
  },
  mounted() {
    // this.loadData();
    //this.getLanguages();
    //this.$gun.get('settings').get('languages').put(null);
    this.$on("update"), ((m) => {
      this.msg = m;
        this.snackbar = true;
    })
    

  },
  created() {
    this.$root.title = "Manage Settings";
    this.$root.admin.extramargin = "390px";
    this.$root.admin.openside = true;
    this.$root.currentpage = "AppSettings";
  },
};
</script>

<style></style>
