<template>
  <v-card flat style="background-color:transparent !important">
    <v-card-title
      style="background-color:transparent !important"
      class="mx-0 px-0"
    >
     <v-toolbar
      color="white"
      class=" mb-3 rounded-lg"
      style="box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);"
    >
      <v-text-field
        v-model="search"
        clearable
        flat
        dense
        single-line
        solo
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
        style="width:100%"
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          rounded
          dense
          :loading="loading"
          :disabled="loading"
          large
          color="primary"
          @click="createNew"
        >
          <v-icon color="white">mdi-plus</v-icon> New Department
        </v-btn>
        <v-btn
          elevation="0"
          rounded
          icon
          :loading="forceloading"
          :disabled="forceloading"
          large
          color="primary"
          @click="forceReload"
        >
          <v-icon color="primary">mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text v-if="departments != null">
      <v-list style=" background-color:transparent !important;  ">
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
          style=" background-color:transparent !important;    
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;"
        >
          <v-list-item
            style="max-width: 24% !important;"
            class="listitem mb-2 pushx"
            two-line
            v-for="depart in searchresult"
            :key="depart.id"
          >
            <v-list-item-avatar class="listitemicon" size="50" color="#f2f7fd">
              <v-img
                :src="$root.assetsBaseURL + '' + depart.avatar.data.asset_url"
                height="20"
                width="20"
                contain
                v-if="depart.avatar != null"
              />
              <v-avatar v-else x-large>
                <span
                  class="font-size-h5 font-weight-bold"
                  style="text-transform: uppercase"
                  >{{ depart.name.charAt(0) }}</span
                >
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ getTranslation(depart).name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ depart.doctors.length }} {{ $t("APPOINTMENTS.add.doctors") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
        <v-snackbar v-model="snackbar" timeout="5000">
      {{ msg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      v-model="openDialog"
      app
      persistent
      temporary
      width="40vw"
      style="border-radius: 0;"
      :right="$root.lang != 'ar'"
    >
      <DepartmentForm ref="departside" @update="processupdate" @close="openDialog = false; selectedItem = null" />
    </v-navigation-drawer>
  </v-card>
</template>

<script>
//import ApiService from "@/core/services/api.service";
import DepartmentForm from "./comp/DepartmentForm.vue";

export default {
  components: { DepartmentForm },
  data() {
    return {
      loading: false,
      openDialog: false,
      dialogDelete: false,
      formTitle: "",
      departments: null,
      selectedItem: null,
      search: "",
      msg:"",
      snackbar:false,
      forceloading:false,
    };
  },
  watch:
  {
    openDialog: function(d)
    {
      if(!d)
      {
        this.selectedItem = null;
        this.$refs.departside.removeData();
      }
    },
    selectedItem: function(s)
    {
      if(s != null)
      {
        this.openside(this.departments[s]);
      }
    }
  },
  methods: {
    forceReload()
    {
      this.forceloading = true;
      this.loadData(true);

    },
    createNew()
    {
      this.$refs.departside.isNew(true);
      this.openDialog = true;
    },
    openside(e)
    {
      //console.log("e",e);
      this.$refs.departside.isNew(false);
      this.$refs.departside.setData(e);
      this.openDialog = true;
    },
    async loadData(force = false) {
      try {
        this.loading = true;
        this.$department.getDepartments(force).then((data)=>{
          this.departments = data;
          this.loading = false;
          if(this.forceloading) this.forceloading = false;
        });
       
      } catch (error) {
        console.log(error);
      }
    },
    processupdate(e)
    {
      if(e.type != 'error' )
      {
        this.loadData(true);
      }
      this.msg = e.msg;
      this.snackbar = true;
    },
    doSelect(e) {
      this.$emit("departmentSelected", e);
    },
    doclose() {
      this.$emit("doclose", true);
    },
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
  },
  mounted() {
    this.loadData();
    this.$root.title = "Manage Departments";
  },
  computed: {
    searchresult() {
      return this.departments.filter((i) => {
        return (
          !this.search ||
          i.translation.find((n) => {
            return n.name.toLowerCase().includes(this.search);
          })
        );
      });
    },
  },
};
</script>
<style scoped>
.theme--light.v-list-item:hover::before {
  border-radius: 16px !important;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  border-radius: 16px !important;
}
</style>
