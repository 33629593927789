<template>
  <v-card flat style="background-color:transparent !important">
    <v-card-title
      style="background-color:transparent !important"
      class="mx-0 px-0"
    >
      <v-toolbar
      color="white"
      class=" mb-3 rounded-lg"
      style="box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);"
    >
      <v-text-field
        v-model="search"
        clearable
        flat
        dense
        single-line
        solo
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
        style="width:100%"
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          rounded
          dense
          :loading="loading"
          :disabled="loading"
          large
          color="primary"
          @click="createNew"
        >
          <v-icon color="white">mdi-plus</v-icon> New Type
        </v-btn>
        <v-btn
          elevation="0"
          rounded
          icon
          :loading="forceloading"
          :disabled="forceloading"
          large
          color="primary"
          @click="forceReload"
        >
          <v-icon color="primary">mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text style="background-color:transparent !important">
      <v-list
        v-if="vitalTypes"
        style=" background-color:transparent !important;"
      >
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
          style=" background-color:transparent !important;    
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;"
        >
          <v-list-item
            style="max-width: 24% !important;"
            class="listitem mb-2 pushx"
            three-line
            v-for="item in searchresult"
            :key="item.id"
          >
            <v-list-item-avatar class="listitemicon" size="50" color="#f2f7fd">
              <v-img
                :src="$root.assetsBaseURL + '' + item.icon.data.asset_url"
                height="20"
                width="20"
                contain
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ getTranslation(item).name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Min: {{ item.minimum.min }} Max: {{ item.maximum.max }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Suffix: {{ item.suffix }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action> </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-snackbar v-model="snackbar" timeout="5000">
      {{ msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer
      v-model="openDialog"
      app
      persistent
      temporary
      width="40vw"
      style="border-radius: 0;"
      :right="$root.lang != 'ar'"
    >
      <VitalTypeForm
        ref="vitalside"
        @close="
          openDialog = false;
          selectedItem = null;
        "
        @update="processupdate"
      />
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import VitalTypeForm from "./comp/VitalTypeForm.vue";

export default {
  components: { VitalTypeForm },
  data() {
    return {
      loading: false,
      openDialog: false,
      dialogDelete: false,
      formTitle: "",
      vitalTypes: null,
      record: null,
      search: "",
      selectedItem: null,
      msg: "",
      snackbar: false,
      forceloading:false,
    };
  },
  watch: {
    openDialog: function(d) {
      if (!d) {
        this.selectedItem = null;
        this.$refs.vitalside.removeData();
      }
    },
    selectedItem: function(s) {
      if (s != null) {
        this.openside(this.vitalTypes[s]);
      }
    },
  },
  methods: {
    forceReload()
    {
      this.forceloading = true;
      this.loadData(true);

    },
    createNew() {
      this.$refs.vitalside.isNew(true);
      this.openDialog = true;
    },
    openside(e) {
      //console.log("e",e);
      this.$refs.vitalside.isNew(false);
      this.$refs.vitalside.setData(e);
      this.openDialog = true;
    },
    async loadData(force = false) {
      try {
        this.loading = true;
        this.$vitalTypes.getItem(force).then((data) => {
          this.vitalTypes = data;
          this.loading = false;
          if(this.forceloading) this.forceloading = false;
        });
      } catch (error) {
        console.log(error);
      }
    },
    processupdate(e) {
      if (e.type != "error") {
        this.loadData(true);
      }
      this.msg = e.msg;
      this.snackbar = true;
    },
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
  },
  mounted() {
    this.loadData();
    this.$root.title = "Manage Vital Types";
  },
  computed: {
    searchresult() {
      return this.vitalTypes.filter((i) => {
        return (
          !this.search ||
          i.translation.find((n) => {
            return n.name.toLowerCase().includes(this.search);
          })
        );
      });
    },
  },
};
</script>

<style scoped>
.theme--light.v-list-item:hover::before {
  border-radius: 16px !important;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  border-radius: 16px !important;
}
</style>
