<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">Edit</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="settingA[Object.keys(settingA)[0]]"
              :rules="[v => !!v || 'This Field Is Required']"
              :label="
                Object.keys(setting)[0] == 'handling_fee'
                  ? 'Fees'
                  : Object.keys(setting)[0] == 'call_duration'
                  ? 'Call Duration'
                  : Object.keys(setting)[0]
              "
              required
            ></v-text-field>
            <!-- <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="settingsForm.handling_fee"
                  :rules="[(v) => !!v || 'Handling Fee Is Required']"
                  label="Handling Fee"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="settingsForm.tax"
                  :rules="[(v) => !!v || 'Tax Is Required']"
                  label="Tax"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="settingsForm.currency"
                  :rules="[(v) => !!v || 'Currency Is Required']"
                  label="Currency"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="settingsForm.call_duration"
                  :rules="[(v) => !!v || 'Call Duration Is Required']"
                  label="Call Duration"
                  required
                ></v-text-field>
              </v-col>
            </v-row> -->
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  props: ["item", "dialog", "setting", "id"],
  data() {
    return {
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      settingA: "",
      settingsForm: {
        handling_fee: "",
        tax: "",
        currency: "",
        call_duration: ""
      }
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          // const settingsPayload = {
          //   handling_fee: this.settingsForm['handling_fee'],
          //   tax: this.settingsForm['tax'],
          //   currency: this.settingsForm['currency'],
          //   call_duration: this.settingsForm['call_duration'],
          // };
          // let settingsPayload = {}
          // settingsPayload[key] = this.settingA[key]

          this.submitted = true;
          await ApiService.update("items/settings", this.id, this.settingA);

          this.submitted = false;
          const msg = "Settings Updated seccessfully";
          this.$emit("formSubmitted", msg);
        }
      } catch (error) {
        if (error.message == "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        console.log(error);
      }
    },
    close() {
      this.$emit("closeDialog");
    }
  },

  // watch: {
  //   setting: function() {
  //     this.settingA = this.setting;
  //   },
  // },
  mounted() {
    console.log(this.setting);
    this.settingA = this.setting;
    // Object.assign(this.settingsForm, this.item);
  }
  // unmounted() {
  //   this.settingA = null;
  //   this.$emit('unmount');
  // },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
